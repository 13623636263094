import React, { useState, useEffect } from "react";
import BasicButton from "./BasicButton";

import { v4 as uuidV4 } from "uuid";
import { useForm } from "react-hook-form";
import styles from "./ShareButton.module.css";

export default function AskButton(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [apiUrl, setApiUrl] = useState("");
  const [projectid, setProjectId] = useState("");

  useEffect(() => {
    console.log(props);
    setApiUrl(props.url);
    setProjectId(props.projectid);
  }, [props]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      console.log("api", apiUrl + "/player/askProject");
      const response = await fetch(apiUrl + "/player/askProject", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ question, projectid }),
      });
      const data = await response.json();
      setAnswer(data.answer);
    } catch (error) {
      console.error("Error fetching answer:", error);
      setAnswer("An error occurred while fetching the answer.");
    } finally {
      setIsLoading(false);
    }
  };

  let icon = "/icons/question-and-answer.png";
  return (
    <>
      <BasicButton
        gray
        smallerIcon
        name="Ask"
        icon={icon}
        style={{ opacity: "40%", transition: "all .15s ease" }}
        onClick={() => {
          window.player.pause();
          setIsOpen(true);
        }}
        tooltip="Ask about this video"
      ></BasicButton>

      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
          style={{ zIndex: 100 }}
        >
          <div className="bg-white rounded-lg p-6 w-full max-w-md max-h-[80vh] flex flex-col relative">
            <h2 className="text-xl font-bold mb-4">Ask a Question, summarize the video, and more !</h2>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="What is your question?"
                className="w-full p-2 border border-gray-300 rounded-md mb-4"
                required
              />
              <button
                type="submit"
                disabled={isLoading}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors disabled:bg-blue-300"
              >
                {isLoading ? "Sending..." : "Send"}
              </button>
            </form>
            {answer && (
              <div className="flex-grow overflow-y-auto">
                <h3 className="font-bold sticky top-0 bg-white py-2">Answer:</h3>
                <p className="mt-2">{answer}</p>
              </div>
            )}
            <button onClick={() => setIsOpen(false)} className="mt-4 text-sm text-gray-500 hover:text-gray-700">
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}
