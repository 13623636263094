import React from "react";
import BasicButton from "./BasicButton";

import { v4 as uuidV4 } from "uuid";

import styles from "./ShareButton.module.css";

export default function EmbedButton(props) {
  const [copySuccess, setCopySuccess] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);
  const [embedCode, setEmbedCode] = React.useState(
    '<iframe frameborder="0" scrolling="no" margin width="788.54" height="443" type="text/html" src="' +
      props.url +
      '#embed?embedVideo=true"></iframe>'
  );
  const [hideScript, setHideScript] = React.useState(true);
  //let shareUrl = props.url;

  React.useEffect(() => {
    if (props.getStatic) {
      setEmbedCode(
        '<iframe frameborder="0" scrolling="no" margin width="788.54" height="443" type="text/html" src="' +
          props.url +
          '#embed?embedVideo=true&getStatic=true"></iframe>'
      );
    } else {
      setEmbedCode(
        '<iframe frameborder="0" scrolling="no" margin width="788.54" height="443" type="text/html" src="' +
          props.url +
          '#embed?embedVideo=true"></iframe>'
      );
    }
  }, [props.url, props.getStatic]);

  let title = props.title;
  let projectid = props.projectid;
  let image = props.image;
  let light = props.light;
  let smallerIcon = false;
  if (props.smallerIcon) smallerIcon = true;

  let data = {
    projectid: projectid,
    fpid: process.browser && window && window.fpid ? window.fpid : null,
    value: 0,
    category: "Video",
    label: "share",
    sessionid: null,
    linkedid: null,
    analyticsid: uuidV4(),
    metadata: {},
    time: new Date(),
  };

  let icon = "/icons/embed_icon.png";
  if (props.darkblue) icon = "/icons/share_icon_dark_blue.png";
  return (
    <>
      <BasicButton
        gray
        smallerIcon
        name="Embed"
        icon={icon}
        style={{ opacity: "40%", transition: "all .15s ease" }}
        onClick={() => setShowModal(true)}
        tooltip="Embed video"
      ></BasicButton>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            style={{ margin: "auto", width: "50%" }}
          >
            <div className="relative w-auto my-6 mx-auto max-w-full">
              {/*content*/}
              <div className="border-0 py-4 px-6 rounded-lg shadow-lg relative flex flex-col w-auto bg-white outline-none focus:outline-none">
                {/*body*/}

                <div className="flex flex-row w-auto">
                  <div
                    className="pb-2 mr-6  cursor-pointer text-sm text-gray-500 overflow-hidden overflow-ellipsis"
                    onClick={() => {
                      navigator.clipboard.writeText(embedCode);

                      setCopySuccess("Link copied to clipboard!");
                    }}
                  >
                    {embedCode}
                  </div>
                  <div
                    className="cursor-pointer text-gray-700 text-sm"
                    onClick={() => {
                      navigator.clipboard.writeText(embedCode);

                      setCopySuccess("Embed code copied to clipboard!");
                    }}
                  >
                    COPY CODE
                  </div>
                </div>
                <div className="flex flex-row w-full">{copySuccess}</div>
                {/*footer*/}
                <div className="flex items-center justify-end pt-1 ">
                  <button
                    className="text-blue background-transparent font-bold uppercase  pt-1 text-sm text-gray-700 outline-none focus:outline-none  mb-1"
                    type="button"
                    style={{ transition: "all .15s ease" }}
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
