import App from "../App";
import config from "../../config";
import Footer from "../Footer";
import { useRouter } from "next/router";
import ShareButton from "../../sharedcomponents/ShareButton";
import AskButton from "../../sharedcomponents/AskButton";
import EmbedButton from "../../sharedcomponents/EmbedButton";
import BasicButton from "../../sharedcomponents/BasicButton";
import ScenesText from "../ScenesText";
import ReactTooltip from "react-tooltip";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

import moment from "moment";
import qs from "qs";
import Skeleton from "react-loading-skeleton";

import React, { useEffect, useState, useCallback } from "react";
import VideoHeader from "./VideoHeader";

function useWindowSize({ initialHeight, initialWidth }) {
  //console.log({ initialWidth, initialHeight });
  const [size, setSize] = useState([initialWidth, initialHeight]);
  useEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function Video({
  videoObj,
  posterImage,
  scenes,
  showHeader,
  showNotes,
  meta,
  elements,
  properties,
  status,
  initialWidth,
  initialHeight,
}) {
  const [width, height] = useWindowSize({ initialWidth, initialHeight });
  //const [videoSize, setVideoSize] = useState({});
  let videoSize = {};
  //const [sceneTextContainerStyle, setSceneTextContainerStyle] = useState({ maxHeight: "100%", display: "none" });
  //let sceneTextContainerStyle = { maxHeight: "100%", display: "none" };
  //const [containerStyle, setContainerStyle] = useState({ marginLeft: 60, marginRight: 60 });
  let containerStyle = { marginLeft: 60, marginRight: 60 };
  //const [marginVideo, setMarginVideo] = useState("mt-6 mb-1");
  let marginVideo = "mt-6 mb-1";
  //const [bodyClass, setBodyClass] = useState("pt-10 sm:pt-16 mt-6 mb-1");
  let bodyClass = "pt-10 sm:pt-16 mt-6 mb-1";
  //const [bodyBackground, setBodyBackground] = useState("bg-gray-100 absolute w-full min-h-screen");
  let bodyBackground = "bg-gray-100 absolute w-full min-h-screen";

  //let stateHideScript = true;
  //const [showButton, setShowButton] = useState(false);
  const showButton = true;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [fullScreen, setFullScreen] = useState("none");
  /*const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []); */

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handle = useFullScreenHandle();

  const router = useRouter();
  //const { hideScript } = router.query;
  let showFooter = true;

  let hideScript = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {
    ignoreQueryPrefix: true,
  }).hideScript;
  if (hideScript && hideScript === "true") {
    hideScript = true;
  } else {
    hideScript = false;
  }

  let embedVideo = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {
    ignoreQueryPrefix: true,
  }).embedVideo;
  if (embedVideo && embedVideo === "true") {
    embedVideo = true;
    hideScript = true;
    showHeader = false;
    showFooter = false;
  } else embedVideo = false;

  let getStatic = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {
    ignoreQueryPrefix: true,
  }).getStatic;
  if (getStatic && getStatic === "true") {
    getStatic = true;
  } else getStatic = false;

  let mutedVideo = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {
    ignoreQueryPrefix: true,
  }).mutedVideo;
  if (mutedVideo && mutedVideo === "true") {
    mutedVideo = "muted";
  } else mutedVideo = true;

  let hideCaption = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {
    ignoreQueryPrefix: true,
  }).hideCaption;
  if (hideCaption && hideCaption === "true") {
    hideCaption = true;
  } else hideCaption = false;

  let pauseAfterEachScene = qs.parse(router.asPath.substring(router.asPath.indexOf("?")), {
    ignoreQueryPrefix: true,
  }).pauseAfterEachScene;
  if (pauseAfterEachScene && pauseAfterEachScene === "true") pauseAfterEachScene = true;
  if (pauseAfterEachScene && video && videoObj && videoObj.attributes)
    videoObj.attributes.pauseAfterEachScene = pauseAfterEachScene;
  let playerdownload = false;
  if (videoObj && videoObj.attributes && videoObj.attributes.playerdownload)
    playerdownload = videoObj.attributes.playerdownload;
  if (videoObj && videoObj.attributes && videoObj.attributes.lockPlayer) hideScript = true;
  let sourcedownload = false;
  if (videoObj && videoObj.attributes && videoObj.attributes.sourcedownload)
    sourcedownload = videoObj.attributes.sourcedownload;

  const [stateHideScript, setStateHideScript] = useState(hideScript);

  const reportChange = useCallback((state, handle) => {
    if (state) {
      setFullScreen("block");
    } else {
      setFullScreen("none");
    }
  });

  if (getStatic) marginVideo = "mt-0 mb-0";

  let videoWidth = width - 60;
  if (embedVideo) {
    videoWidth = width - 1;
  }
  if (width > 767 && !embedVideo) videoWidth = Math.min(width - 150, width * 0.55);

  let adjustedHeight = videoWidth / properties.widthRatio;

  let textStyleSkeleton = {
    height: adjustedHeight + 20,
    maxWidth: "120vh",
    minWidth: width - videoWidth - 150,
  };

  //useEffect(() => {
  //setShowButton(true);
  //if (width && height && properties && properties.widthRatio) {
  let maxVideoHeight = height - 200;
  let normalVideoHeight = height - 300;

  if (embedVideo) {
    maxVideoHeight = height;
    normalVideoHeight = height;
  }

  // set script and video styles

  containerStyle = { marginLeft: 40, marginRight: 40 };

  if (adjustedHeight > maxVideoHeight) {
    adjustedHeight = maxVideoHeight;
    videoWidth = adjustedHeight * properties.widthRatio;
  } else if (width > 767 && adjustedHeight > normalVideoHeight) {
    adjustedHeight = normalVideoHeight;
    videoWidth = adjustedHeight * properties.widthRatio;
  }

  /*
      if (adjustedHeight > height - 300 && height > 470) {
        adjustedHeight = height - 300;
        videoWidth = adjustedHeight * widthRatio;

        newHeight = height - 285 + 115;
        videoStyle.minHeight = newHeight;
      } */

  let textStyle = {
    display: "block",
    maxHeight: adjustedHeight + 20,
    maxWidth: "120vh",
    /*minWidth: width - videoWidth - 150, */
    minHeight: adjustedHeight,
  };

  if (width - videoWidth - 120 > 300) textStyle.maxWidth = width * 0.35 - 50; //width - videoWidth - 150;

  let videoStyle = {};
  videoStyle.width = videoWidth;
  if (width < 767) {
    delete videoStyle.marginRight;
    containerStyle.marginRight = 30;
    containerStyle.marginLeft = 30;
    textStyle.minWidth = videoWidth;
  }

  //console.log({ adjustedHeight, width, height, videoWidth, textStyle });

  if (embedVideo) {
    containerStyle.marginRight = 0;
    containerStyle.marginLeft = 0;
  }

  let sceneTextContainerStyle = textStyle;
  videoSize = videoStyle;
  //stateHideScript = hideScript;

  //setSceneTextContainerStyle(textStyle);
  //setVideoSize(videoStyle);
  //setContainerStyle(containerStyle);
  //setStateHideScript(hideScript);

  let margin = " mt-24 md:mt-16 mb-60";

  if (!showHeader) margin = " mt-6 md:mt-6 mb-60";

  bodyClass = "pt-10 sm:pt-16  " + margin;
  //setBodyClass("pt-10 sm:pt-16  " + margin);
  if (embedVideo) {
    bodyClass = "pt-0 sm:pt-0  mt-0 md:mt-0";
    marginVideo = "mt-0 mb-0";
    bodyBackground = "bg-gray-0 absolute w-full min-h-screen";
  }
  //}
  //}, [width, height, properties]);

  /*

  if (process.browser) {
    console.log("window height", window.innerHeight);
    console.log("window width", window.innerWidth);
    let videoWidth = window.innerHeight * widthRatio;
    console.log("videoWidth", videoWidth);
    videoSize.width = videoWidth;
  } */

  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  if (router.isFallback) {
    return <div>Loading...</div>;
  }

  if (!videoObj) {
    console.log("no videoObj");
    throw new Error("no videObj");
  }

  // meta information
  let title = videoObj.name + " | Automate.video";
  let name = videoObj.name;
  let description = meta.description;
  if (description.length === 0) description = name;
  let url = config.player + "/" + videoObj.projectid;
  //let previewImageUrl = videoObj.urls.previewImageUrl;
  let previewImageUrl = config.mediacache + "/api/cache-getVideoThumbnail?projectid=" + videoObj.projectid;
  let created = moment(videoObj.created).format("YYYY-MM-DD");
  let duration = Math.round(videoObj.attributes.duration) || 0;
  let duration8601 = moment.duration({ seconds: duration }).toISOString();
  //"PT" + duration > 3600 ? Math.floor(duration / 3600) + "H" + Math.floor((duration % 3600) / 60) + "M" + (duration %3600)/60 % 60 + "S": duration> 60 ? Math.floor(duration/60) +"M"+ (duration%60);
  let jsonld = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    name: name,
    description: description,
    thumbnailUrl: [previewImageUrl],
    uploadDate: created,
    duration: duration8601,
    contentUrl: url,
    embedUrl: url,
  };

  let oembedLink = config.player + "/api/oembed?url=" + url + "&amp;format=json";

  let titleStyle = { color: "#bfbbbb" };

  if (elements.categoryFeatured === "business") titleStyle.color = "#31a4c6";
  if (elements.categoryFeatured === "health") titleStyle.color = "#fb6540";
  if (elements.categoryFeatured === "environment") titleStyle.color = "#66ad24";
  if (elements.categoryFeatured === "arts") titleStyle.color = "#a3af2f";
  if (elements.categoryFeatured === "school") titleStyle.color = "#e74fc6";

  return (
    <div className={bodyBackground}>
      <VideoHeader showHeader={showHeader} videoObj={videoObj} meta={meta} />
      <div className={bodyClass}>
        <div className="flex flex-row flex-wrap items-start gap-8" style={containerStyle}>
          <div className="flex flex-col md:w-1/2 shadow-md rounded-lg overflow-hidden bg-white " style={videoSize}>
            <div className={marginVideo}>
              {typeof window !== "undefined" && getStatic ? (
                <div>
                  <Document file={videoObj.urls.pdfDownloadUrl} onLoadSuccess={onDocumentLoadSuccess}>
                    {sceneTextContainerStyle.with + videoSize.width < width ? (
                      <Page pageNumber={pageNumber} height={videoSize.height - 105} />
                    ) : (
                      <Page pageNumber={pageNumber} width={videoSize.width - 105} />
                    )}
                  </Document>

                  <FullScreen
                    handle={handle}
                    onChange={reportChange}
                    className="bg-gray-100 overflow-y-auto overflow-x-hidden"
                  >
                    <div
                      style={{
                        display: fullScreen,
                        background: "white repeat-y",
                        height: "100%",
                      }}
                    >
                      <Document file={videoObj.urls.pdfDownloadUrl}>
                        {videoSize.width < videoSize.height ? (
                          <Page pageNumber={pageNumber} width={width - 30} />
                        ) : (
                          <Page pageNumber={pageNumber} width={width - 30} />
                        )}
                      </Document>
                      <p
                        style={{
                          backgroundColor: "rgba(255,255,255,0.4)",
                          position: "fixed",
                          bottom: "1em",
                          right: "2em",
                          fontSize: "2em",
                        }}
                      >
                        <BasicButton
                          gray
                          smallerIcon
                          name="Previous"
                          icon="/icons/previous.png"
                          style={{
                            transition: "all .15s ease",
                            float: "left",
                            opacity: "100%",
                            width: "60px",
                            height: "60px",
                          }}
                          onClick={() => {
                            setPageNumber(Math.max(pageNumber - 1, 1));
                          }}
                        ></BasicButton>
                        <span style={{ verticalAlign: "50%" }}>
                          {pageNumber} of {numPages}
                        </span>
                        <BasicButton
                          gray
                          smallerIcon
                          name="Next"
                          icon="/icons/next.png"
                          style={{
                            transition: "all .15s ease",
                            float: "right",
                            opacity: "100%",
                            width: "60px",
                            height: "60px",
                          }}
                          onClick={() => {
                            setPageNumber(Math.min(pageNumber + 1, numPages));
                          }}
                        ></BasicButton>
                        <BasicButton
                          gray
                          smallerIcon
                          name="Full Screen"
                          icon="/icons/fullscreenexit.png"
                          style={{
                            transition: "all .15s ease",
                            float: "left",
                            opacity: "80%",
                            width: "60px",
                            height: "60px",
                          }}
                          onClick={() => {
                            handle.exit();
                            setFullScreen("none");
                          }}
                        ></BasicButton>
                      </p>
                    </div>
                  </FullScreen>
                </div>
              ) : null}
              {typeof window !== "undefined" && !embedVideo && !getStatic ? (
                <App
                  video={videoObj}
                  projectid={videoObj.projectid}
                  posterImage={posterImage}
                  adjustedHeight={adjustedHeight}
                />
              ) : (
                <>
                  <VideoHeader videoObj={videoObj} meta={meta} showHeader={showHeader} />
                  <img src={posterImage} height={adjustedHeight} width={videoWidth} />
                  <div className="flex flex-col border shadow-md rounded-lg overflow-y-auto bg-white  ">
                    <div
                      className="flex flex-col  shadow-md rounded-lg overflow-y-auto bg-white  "
                      style={textStyleSkeleton}
                    ></div>
                  </div>
                </>
              )}
              {typeof window !== "undefined" && embedVideo && !getStatic ? (
                <App
                  video={videoObj}
                  projectid={videoObj.projectid}
                  posterImage={posterImage}
                  autoplay={mutedVideo}
                  showNotes={showNotes}
                  hideCaption={hideCaption}
                  status={status}
                />
              ) : null}
            </div>
            {elements && elements.categoryFeatured ? (
              <div className="text-lg mx-5 mt-1" style={titleStyle}>
                {elements.categoryFeatured.charAt(0).toUpperCase() + elements.categoryFeatured.slice(1)}
              </div>
            ) : null}
            {!embedVideo && !getStatic ? (
              <div className="flex flex-row mt-0 mb-2 ml-5 mr-1 text-gray-600 justify-between">
                <span>
                  <h1
                    className="font-bold text-xl md:text-xl  mx-5 mb-2 mt-1 break-all overflow-hidden "
                    style={titleStyle}
                  >
                    {videoObj.name}
                  </h1>
                </span>
              </div>
            ) : null}
            {!embedVideo && getStatic ? (
              <div className="flex flex-row mt-0 mb-0 ml-5 mr-1 text-gray-600 justify-between">
                <span>
                  <h1
                    className="font-bold text-xl md:text-xl  mx-5 mb-0 mt-0 break-all overflow-hidden "
                    style={titleStyle}
                  >
                    {videoObj.name}
                  </h1>
                </span>
                <span>
                  <BasicButton
                    gray
                    smallerIcon
                    name="Previous"
                    icon="/icons/previous.png"
                    style={{
                      transition: "all .15s ease",
                      float: "right",
                      opacity: "100%",
                      width: "30px",
                      height: "30px",
                    }}
                    onClick={() => {
                      setPageNumber(Math.max(pageNumber - 1, 1));
                    }}
                  ></BasicButton>
                  <span style={{ verticalAlign: "50%" }}>
                    {pageNumber} of {numPages}
                  </span>
                  <BasicButton
                    gray
                    smallerIcon
                    name="Next"
                    icon="/icons/next.png"
                    style={{
                      transition: "all .15s ease",
                      float: "right",
                      opacity: "100%",
                      width: "30px",
                      height: "30px",
                    }}
                    onClick={() => {
                      setPageNumber(Math.min(pageNumber + 1, numPages));
                    }}
                  ></BasicButton>
                  <BasicButton
                    gray
                    smallerIcon
                    name="Full Screen"
                    icon="/icons/fullscreen.png"
                    style={{
                      transition: "all .15s ease",
                      float: "right",
                      opacity: "80%",
                      width: "30px",
                      height: "30px",
                    }}
                    onClick={() => {
                      handle.enter();
                      setFullScreen("block");
                    }}
                  ></BasicButton>
                </span>
              </div>
            ) : null}
            {!embedVideo ? (
              <div className="flex flex-row mt-1 mb-2 ml-5 mr-1 text-gray-600 justify-between">
                <span>
                  Published on{" "}
                  <time itemProp="datePublished" dateTime={created}>
                    {moment(videoObj.created).format("MMM DD, YYYY")}
                  </time>
                </span>
                <span>
                  {showButton && !getStatic && config.player && videoObj.urls?.pdfDownloadUrl ? (
                    <React.Fragment>
                      <a data-tip data-for="slideshow" href={"/" + videoObj.projectid + "?getStatic=true"}>
                        <img
                          style={{
                            transition: "all .15s ease",
                            float: "right",
                            opacity: "35%",
                            width: "40px",
                            height: "30px",
                            marginRight: "15px",
                          }}
                          alt="Slideshow"
                          src="/icons/slideshow.png"
                        />
                      </a>
                      <ReactTooltip id="slideshow" place="left" type="dark" effect="float">
                        Static slideshow
                      </ReactTooltip>
                    </React.Fragment>
                  ) : null}
                  {showButton && getStatic && config.player ? (
                    <React.Fragment>
                      <a data-tip data-for="gotovideo" href={config.player + "/" + videoObj.projectid}>
                        <img
                          style={{
                            transition: "all .15s ease",
                            float: "right",
                            opacity: "35%",
                            width: "30px",
                            height: "30px",
                            marginRight: "15px",
                          }}
                          alt="Video"
                          src="/icons/multimedia.png"
                        />
                      </a>
                      <ReactTooltip id="gotovideo" place="left" type="dark" effect="float">
                        Go to video
                      </ReactTooltip>
                    </React.Fragment>
                  ) : null}
                  {showButton && sourcedownload ? (
                    <React.Fragment>
                      <a data-tip data-for="source-view" href={videoObj.urls.pptxDownloadUrl}>
                        <img
                          style={{
                            transition: "all .15s ease",
                            float: "right",
                            opacity: "35%",
                            width: "30px",
                            height: "30px",
                            marginRight: "15px",
                          }}
                          alt="Download presentation"
                          src="/icons/ppt_icon.png"
                        />
                      </a>
                      <ReactTooltip id="source-view" place="left" type="dark" effect="float">
                        Download Presentation
                      </ReactTooltip>
                    </React.Fragment>
                  ) : null}
                  {showButton && playerdownload ? (
                    <React.Fragment>
                      <a data-tip data-for="offline-view" href={videoObj.urls.playerDownloadUrl}>
                        <img
                          style={{
                            transition: "all .15s ease",
                            float: "right",
                            opacity: "35%",
                            width: "30px",
                            height: "30px",
                            marginRight: "15px",
                          }}
                          alt="View Offline"
                          src="/icons/playerdownload.png"
                        />
                      </a>
                      <ReactTooltip id="offline-view" place="left" type="dark" effect="float">
                        Download Offline version
                      </ReactTooltip>
                    </React.Fragment>
                  ) : null}
                  {showButton && videoObj.urls?.pdfDownloadUrl ? (
                    <React.Fragment>
                      <a data-tip data-for="pdf-download" href={videoObj.urls.pdfDownloadUrl}>
                        <img
                          style={{
                            transition: "all .15s ease",
                            float: "right",
                            opacity: "40%",
                            width: "30px",
                            height: "30px",
                            marginRight: "10px",
                          }}
                          alt="Download PDF version"
                          src="/icons/download.png"
                        />
                      </a>
                      <ReactTooltip id="pdf-download" place="left" type="dark" effect="float">
                        Download PDF version
                      </ReactTooltip>
                    </React.Fragment>
                  ) : null}
                  {showButton ? (
                    <EmbedButton
                      projectid={videoObj.projectid}
                      url={config.player + "/" + videoObj.projectid}
                      title={videoObj.name}
                      image={videoObj.urls.previewImageUrl}
                      getStatic={getStatic}
                    />
                  ) : null}
                  {showButton ? (
                    <ShareButton
                      projectid={videoObj.projectid}
                      url={config.player + "/" + videoObj.projectid}
                      title={videoObj.name}
                      image={videoObj.urls.previewImageUrl}
                      getStatic={getStatic}
                    />
                  ) : null}
                  {showButton ? <AskButton projectid={videoObj.projectid} url={config.socketIo} /> : null}
                </span>
              </div>
            ) : null}
          </div>{" "}
          {!stateHideScript ? (
            <div className="flex flex-col border shadow-md rounded-lg overflow-y-auto bg-white">
              <BasicButton
                gray
                smallerIcon
                name="Close"
                icon="/icons/close_icon.png"
                style={{
                  transition: "all .15s ease",
                  float: "right",
                  opacity: "40%",
                  width: "25px",
                  height: "25px",
                }}
                onClick={() => {
                  setStateHideScript(true);
                }}
              ></BasicButton>
              <div
                className="flex flex-col shadow-md rounded-lg overflow-y-auto bg-white"
                style={sceneTextContainerStyle}
              >
                <ScenesText scenes={scenes} getStatic={getStatic} setPageNumber={setPageNumber} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {showFooter ? <Footer /> : null}
    </div>
  );
}

export default Video;
