import config from "../../config";

import Header from "../Header";
import moment from "moment";
import Head from "next/head";
import { NextSeo } from "next-seo";

export default function VideoHeader({ videoObj, showHeader, meta }) {
  // meta information
  let title = videoObj.name + " | Automate.video";
  let name = videoObj.name;
  let description = meta.description ?? "";
  if (description?.length === 0) description = name;
  let url = config.player + "/" + videoObj.projectid;
  //let previewImageUrl = videoObj.urls.previewImageUrl;
  let previewImageUrl = config.mediacache + "/api/cache-getVideoThumbnail?projectid=" + videoObj.projectid;
  let created = moment(videoObj.created).format("YYYY-MM-DD");
  let duration = Math.round(videoObj.attributes?.duration) || 0;
  let duration8601 = moment.duration({ seconds: duration }).toISOString();
  //"PT" + duration > 3600 ? Math.floor(duration / 3600) + "H" + Math.floor((duration % 3600) / 60) + "M" + (duration %3600)/60 % 60 + "S": duration> 60 ? Math.floor(duration/60) +"M"+ (duration%60);
  let jsonld = {
    "@context": "https://schema.org",
    "@type": "VideoObject",
    name: name,
    description: description,
    thumbnailUrl: [previewImageUrl],
    uploadDate: created,
    duration: duration8601,
    contentUrl: url,
    embedUrl: url,
  };

  let oembedLink = config.player + "/api/oembed?url=" + url + "&amp;format=json";
  return (
    <>
      <Head>
        <link rel="icon" href="/favicon.ico" />
        <link rel="alternate" type="text/json+oembed" href={oembedLink}></link>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonld) }} />
      </Head>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url: url,
          title: name,
          description: description,
          images: [{ url: previewImageUrl }],
          type: "video.other",
          video: { duration: duration, releaseDate: created },
          site_name: "Automate.video",
        }}
        facebook={{
          appId: "333616728056159",
        }}
        twitter={{
          site: "@automatevideo",
          cardType: "summary_large_image",
        }}
      />
      {showHeader ? <Header /> : null}
    </>
  );
}
