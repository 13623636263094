import styles from "./ScenesText.module.css";
import moment from "moment";

export default function ScenesText(props) {
  let scenes = props.scenes;
  let getStatic = props.getStatic;
  return (
    <div className={styles.ScenesTextContent}>
      <div className={styles.ScenesTextInner}>
        <div className="p-4">
          {scenes.map((s, idx) => {
            if (!(s.hasOwnProperty("description") && s.description) || (s.hasOwnProperty("hasAvatar") && s.hasAvatar)) {
              return (
                <div key={s.sceneid}>
                  <a
                    className="text-blue"
                    href="#"
                    onClick={(event) => {
                      if (!getStatic) {
                        window.passedScene = idx;
                        window.player.currentTime(s.startTime);
                        window.player.play();
                      } else {
                        props.setPageNumber(idx + 1);
                      }
                    }}
                  >
                    <h5 className="font-bold text-lg mt-2">
                      {getStatic ? "Page " + (idx + 1) : s.name}
                      <span className="text-sm text-gray-500">
                        {" "}
                        {" (" +
                          (s.startTime > 3600
                            ? moment("2015-01-01").startOf("day").seconds(s.startTime).format("H[h ] m[m ] s[s]")
                            : s.startTime > 60
                            ? moment("2015-01-01").startOf("day").seconds(s.startTime).format("m[m ] s[s]")
                            : moment("2015-01-01").startOf("day").seconds(s.startTime).format("s[s]")) +
                          ")"}
                      </span>
                    </h5>
                  </a>
                  {!s.hasAvatar
                    ? s.placeholders.map((ph) => {
                        if (ph.type === "avatar")
                          return (
                            <p key={ph.placeholderid} style={{ wordBreak: "break-word" }}>
                              [Audio] {ph.data?.script}
                            </p>
                          );
                        else if (ph.type === "text")
                          return (
                            <p key={ph.placeholderid} style={{ wordBreak: "break-word" }}>
                              {ph.data?.text}
                            </p>
                          );
                        else
                          return (
                            <p key={ph.placeholderid} style={{ wordBreak: "break-word" }}>
                              {ph.data?.altText}
                            </p>
                          );
                      })
                    : s.placeholders.map((ph) => {
                        if (ph.type === "avatar")
                          return (
                            <p key={ph.placeholderid} style={{ wordBreak: "break-word" }}>
                              {ph.data?.script}
                            </p>
                          );
                        else if (ph.type === "avatarvideo")
                          return (
                            <p key={ph.placeholderid} style={{ wordBreak: "break-word" }}>
                              {ph.data?.script}
                            </p>
                          );
                      })}
                </div>
              );
            } else {
              return (
                <div key={s.sceneid}>
                  <p>{s.hasAvatar}</p>
                  <a
                    className="text-blue"
                    href="#"
                    onClick={(event) => {
                      if (!getStatic) {
                        window.passedScene = idx;
                        window.player.currentTime(s.startTime);
                        window.player.play();
                      } else {
                        props.setPageNumber(idx + 1);
                      }
                    }}
                  >
                    <h5 className="font-bold text-lg mt-2">
                      {getStatic ? "Page " + (idx + 1) : s.name}
                      <span className="text-sm text-gray-500">
                        {" "}
                        {" (" +
                          (s.startTime > 3600
                            ? moment("2015-01-01").startOf("day").seconds(s.startTime).format("H[h ] m[m ] s[s]")
                            : s.startTime > 60
                            ? moment("2015-01-01").startOf("day").seconds(s.startTime).format("m[m ] s[s]")
                            : moment("2015-01-01").startOf("day").seconds(s.startTime).format("s[s]")) +
                          ")"}
                      </span>
                    </h5>
                  </a>
                  <p style={{ wordBreak: "break-word" }}> {s.description}</p>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
