import React from "react";
import ReactDOM from "react-dom";
import moment from "moment";

import videojs from "video.js";

import "./videojs-markers.js";
import "./videojs-analytics.js";
import ModalDialog2 from "./ModalDialog";

import jss from "jss";
import preset from "jss-preset-default";

import { useSwipeable } from "react-swipeable";

import "videojs-overlay";

jss.setup(preset());

let ZingTouch = null;
async () => {
  ZingTouch = await import("zingtouch");
};

const Swipeable = ({ children, ...props }) => {
  const handlers = useSwipeable(props);
  return <div {...handlers}>{children}</div>;
};

export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
      customModals: [],
      placeholders: {},
      controlsHidden: false,
      playBtnHidden: false,
      hidePlayButton: false,
      player_locked: false,
      videoStopped: false,
      hotlinkPhs: [],
      scenes: [],
      answers: [],
      overlaysPos: [],
      pausePositions: [],
      playerCurrentHeight: 0,
      playerCurrentWidth: 0,
      dynamicElementsUpdated: false,
      resizedOnceAfterStart: false,
      currentCues: "1",
      displayNotes: { display: "none" },
      gotcaptionhiding: false,
      gotcaptionshowing: false,
      captionstring: null,
      captionstext: null,
      videoloadeddata: false,
    };
    this.placeholders = {};
    this.lockedPlaceholders = [];
    this.passedSeconds = 0;
    this.currentTime = 0;
    this.passedScene = 0;
    this.placeholdersInserted = 0;
    this.placeholdersRaw = [];
    this.onClosePlaceholder = this.onClosePlaceholder.bind(this);
    this.playerHeight = this.playerHeight.bind(this);
    this.playerWidth = this.playerWidth.bind(this);
    this.pauseMedia = this.pauseMedia.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.pauseMedia = this.pauseMedia.bind(this);
    this.checkTime = this.checkTime.bind(this);
    this.endCheckTime = this.endCheckTime.bind(this);
    this.startCheckTime = this.startCheckTime.bind(this);
    this.swipeEvents = this.swipeEvents.bind(this);
    this.visiblePlaceholders = [];
  }

  testCode() {
    return 5;
  }
  getPlaceholders() {
    return this.placeholdersRaw;
  }

  addPlaceholder(placeholder) {
    if (placeholder.type != "h5p") {
      return;
    }
    if ("start_time" in placeholder) {
    } else {
      throw "start_time not found";
    }
    placeholder.id = this.placeholdersInserted;
    let placeholders = this.placeholders;
    let t = placeholder.start_time;
    if (placeholder.attributes && placeholder.attributes.hide_close === "true") {
      this.lockedPlaceholders.push({ id: placeholder.id, attempted: false, time: placeholder.start_time });
    }
    if (this.placeholderExists(t)) {
      this.placeholders[t].push(placeholder);
    } else {
      this.placeholders[t] = [];
      this.placeholders[t].push(placeholder);
    }
    this.placeholdersRaw.push(placeholder);
    this.placeholdersInserted++;
    // console.log(this.player.markers());
  }

  updatePlaceholder(placeholder) {
    for (var i = this.placeholders.length - 1; i >= 0; i--) {
      var timedPlaceholder = this.placeholders[i];
      for (var j = timedPlaceholder.length - 1; j >= 0; j--) {
        if (timedPlaceholder[j].id === placeholder.id) {
          this.placeholders[i][j] = placeholder;
        }
      }
    }

    for (var i = this.lockedPlaceholders.length - 1; i >= 0; i--) {
      var p = this.lockedPlaceholders[i];
      if (p.id === placeholder.id) {
        this.lockedPlaceholders[i].attempted = placeholder.attempted;
      }
    }
    this.savePlaceholders();
  }

  anyLockedPlaceholdersSkipped(currentTime) {
    for (var i = this.lockedPlaceholders.length - 1; i >= 0; i--) {
      var p = this.lockedPlaceholders[i];
      if (p.time < currentTime) {
        if (!p.attempted) {
          return { time: p.time, id: p.id };
        }
      }
    }
    return false;
  }

  placeholderExists(time) {
    if (time in this.placeholders) {
      return true;
    }
    return false;
  }

  getPlaceholder(time) {
    return this.state.placeholders[time];
  }

  getPlaceholderByTime(time) {
    if (this.placeholderExists(time)) {
      return this.getPlaceholder(time);
    }
    return false;
  }

  savePlaceholders() {
    this.setState({ placeholders: this.placeholders });
  }

  handleResize() {
    console.log("window resizing");
    //let width = this.player.currentWidth();
    //let height = this.player.currentHeight();
    // if (width !== this.state.playerCurrentWidth || height !== this.state.playerCurrentHeight) {
    //  this.setState({ playerCurrentHeight: height, playerCurrentWidth: width });
    //this.setOverlays();
    //}

    let width = this.player.currentWidth();
    let height = this.player.currentHeight();
    //console.log("PLAYER CURRENT Width", width, "height", height);
    let left = 0;
    let top = 0;
    var array = Array.prototype.slice.call(this.player.el().childNodes);
    for (let a of array) {
      //console.log(a.nodeName, window.getComputedStyle(a).width, window.getComputedStyle(a).height);
      if (a.nodeName === "VIDEO") {
        width = window.getComputedStyle(a).width;

        height = window.getComputedStyle(a).height;
      }
    }
    //console.log("VIDEO Width", width, "height", height);
    //console.log("excluding sccrollbars", document.documentElement.clientHeight, document.documentElement.clientWidth);
    //console.log("including scrollbars", window.innerHeight, window.innerWidth);
    let widthFloat = parseFloat(width);
    let heightFloat = parseFloat(height);

    // adjust toolbar
    console.log("WIDTH", widthFloat);
    if (widthFloat < 350) {
      let curTime = document.getElementsByClassName("vjs-time-control");
      for (let c of curTime) c.style.display = "none";
    } else {
      let curTime = document.getElementsByClassName("vjs-time-control");
      for (let c of curTime) c.style.display = "inherit";
      let remTime = document.getElementsByClassName("vjs-remaining-time");
      for (let c of remTime) c.style.display = "none";
    }

    if (widthFloat < 400) {
      let remTime = document.getElementsByClassName("vjs-chapters-button");
      for (let c of remTime) c.style.display = "none";
    } else {
      if (this.props.video && this.props.video.attributes && this.props.video.attributes.lockPlayer) {
        let remTime = document.getElementsByClassName("vjs-chapters-button");
        for (let c of remTime) c.style.display = "none";
      } else {
        let remTime = document.getElementsByClassName("vjs-chapters-button");
        for (let c of remTime) c.style.display = "inherit";
      }
    }

    if (widthFloat < 400) {
      let capText = document.getElementsByClassName("vjs-subs-caps-button");
      for (let c of capText) c.style.display = "none";
    } else {
      let capText = document.getElementsByClassName("vjs-subs-caps-button");
      for (let c of capText) c.style.display = "inherit";
    }

    if (widthFloat < 900) {
      let parControls = document.getElementsByClassName("participant-controls");
      for (let p of parControls) p.style.display = "none";
    } else {
      let parControls = document.getElementsByClassName("participant-controls");
      for (let p of parControls) p.style.display = "inherit";
    }

    let widthRatio = 1;
    let heightRatio = 1;
    if (this.props.video && this.props.video.attributes) {
      widthRatio = widthFloat / this.props.video.attributes.width;
      heightRatio = heightFloat / this.props.video.attributes.height;
    }
    //console.log("widthratio", widthRatio);
    //console.log("heightratio", heightRatio);

    // calculate estimated margin on top or left
    if (widthRatio > heightRatio) left = ((widthRatio - heightRatio) * this.props.video.attributes.width) / 2;
    if (widthRatio < heightRatio) top = ((heightRatio - widthRatio) * this.props.video.attributes.height) / 2;
    let adjustRatio = Math.min(widthRatio, heightRatio);

    for (let ph of this.state.overlaysPos) {
      let otop = null;
      let oleft = null;
      let owidth = null;
      let oheight = null;
      if (ph.hasOwnProperty("top")) otop = Math.max(0, ph.top);
      if (ph.hasOwnProperty("left")) oleft = Math.max(0, ph.left);
      if (ph.hasOwnProperty("width")) {
        owidth =
          oleft + left > this.props.video.attributes.width ? this.props.video.attributes.width - oleft : ph.width;
      }
      if (ph.hasOwnProperty("height")) {
        oheight =
          otop + top > this.props.video.attributes.height ? this.props.video.attributes.height - otop : ph.height;
      }

      let element = document.getElementById(ph.id);

      if (otop) {
        let topOffset = 0;
        let bottomOffset = 0;

        if (ph.hasOwnProperty("topOffset")) topOffset = ph.topOffset;
        if (ph.hasOwnProperty("bottomOffset")) bottomOffset = ph.bottomOffset;
        let newtop = top + otop * adjustRatio + topOffset;

        let bottomCorrection =
          newtop + bottomOffset <= heightFloat ? 0 : Math.round(newtop + bottomOffset - heightFloat);

        element.style.top = Math.round(newtop - bottomCorrection) + "px";
      }
      /* if (ph.hasOwnProperty("internaltop")) {
        let topOffset = 0;    
        let bottomOffset = 0;
        if (ph.hasOwnProperty("topOffset")) topOffset = ph.topOffset;
        if(ph.hasOwnProperty("bottomOffset")) bottomOffset = ph.bottomOffset
        
        if(newtop + bottomOffset) 
        let newtop = Math.round(ph.internaltop * adjustRatio + topOffset);
        element.style.top = newtop + "px";
      }*/
      if (oleft) {
        let leftOffset = 0;
        if (ph.hasOwnProperty("leftOffset")) leftOffset = ph.leftOffset;
        let newLeftPos = left + oleft * adjustRatio + leftOffset;
        element.style.left = Math.round(newLeftPos) + "px";
      } /*
      if (ph.hasOwnProperty("internalleft")) {
        let leftOffset = 0;
        if (ph.hasOwnProperty("leftOffset")) leftOffset = ph.leftOffset;
        element.style.left = Math.round(ph.internalleft * adjustRatio + leftOffset) + "px";
      } */

      if (oheight) element.style.height = Math.round(oheight * adjustRatio) + "px";
      if (owidth) element.style.width = Math.round(owidth * adjustRatio) + "px";
      let element2 = document.getElementById(ph.id);
    }
  }

  setOverlays() {
    console.log("set overlays");
    if (window !== "undefined") console.log("window object exists");
    let overlays = [];
    let overlaysPos = [];
    let pausePositions = [];
    let width = this.player.currentWidth();
    let height = this.player.currentHeight();
    let left = 0;
    let top = 0;
    var array = Array.prototype.slice.call(this.player.el().childNodes);
    for (let a of array) {
      //console.log(a.nodeName, window.getComputedStyle(a).width, window.getComputedStyle(a).height);
      if (a.nodeName === "VIDEO") {
        width = window.getComputedStyle(a).width;

        height = window.getComputedStyle(a).height;
      }
    }
    //console.log("Width", width, "height", height);
    let widthFloat = parseFloat(width);
    let heightFloat = parseFloat(height);

    let widthRatio = 1;
    let heightRatio = 1;
    if (this.props.video && this.props.video.attributes) {
      widthRatio = widthFloat / this.props.video.attributes.width;
      heightRatio = heightFloat / this.props.video.attributes.height;
    }
    //console.log("widthratio", widthRatio);
    //console.log("heightratio", heightRatio);

    // calculate estimated margin on top or left
    if (widthRatio > heightRatio) left = ((widthRatio - heightRatio) * this.props.video.attributes.width) / 2;
    if (widthRatio < heightRatio) top = ((heightRatio - widthRatio) * this.props.video.attributes.height) / 2;
    let adjustRatio = Math.min(widthRatio, heightRatio);

    // add next previous buttons if video should pause after each scene
    for (let [idx, scene] of this.state.scenes.entries()) {
      let gotHotlink = false;
      if (this.state.hotlinkPhs.length > 0) {
        for (let l of this.state.hotlinkPhs) {
          if (l.sceneNumber === idx + 1) {
            gotHotlink = true;
          }
        }
      }
      if (!gotHotlink && (this.props.video.attributes.pauseAfterEachScene || scene.pauseAfter)) {
        let videoHeight = this.props.video.attributes.height;
        let videoWidth = this.props.video.attributes.width;
        let idLeftArrow = "leftArrow-" + idx + 1;
        let leftArrowLeft = 0;

        let leftArrowTop = videoHeight / 2 - 10;

        if (idx > 0) {
          let contentLeft = '<a id="' + idLeftArrow + '" class="sliderLeft" ';
          /*   'style="left:' +
            Math.round(left + leftArrowLeft) +
            "px;top:" +
            Math.round(top + leftArrowTop * adjustRatio) +
            'px;"';*/

          contentLeft +=
            ' onclick="window.passedScene=' +
            (idx - 1) +
            ";videojs.currentTime(" +
            parseFloat(this.state.scenes[idx - 1].startTime) +
            ');videojs.play(); "';
          contentLeft += "> </a>";
          overlays.push({
            align: "",
            start: scene.startTime,
            end: scene.endTime,
            content: contentLeft,
          });
          overlaysPos.push({
            id: idLeftArrow,
            left: leftArrowLeft,
            leftOffset: 10,
            top: leftArrowTop,
          });
          console.log("adding left arrow");
        }
        if (idx < this.state.scenes.length - 1) {
          let rightArrowPos = {
            top: top + (videoHeight * adjustRatio) / 2 - 10,
            left: left + videoWidth * adjustRatio - 50,
            width: 40,
            height: 40,
          };
          let idRightArrow = "rightArrow-" + idx + 1;
          let contentRight = '<a id="' + idRightArrow + '" class="sliderRight" ';
          /* 'style="left:' +
            Math.round(rightArrowPos.left) +
            "px !important;top:" +
            Math.round(rightArrowPos.top) +
            'px !important;"' + */

          contentRight +=
            ' onclick="window.passedScene=' +
            idx +
            ";videojs.currentTime(" +
            parseFloat(this.state.scenes[idx + 1].startTime) +
            ');videojs.play(); "';
          contentRight += "> </a>";
          overlays.push({
            align: "",
            start: scene.startTime,
            end: scene.endTime,
            content: contentRight,
          });
          overlaysPos.push({
            id: idRightArrow,
            left: videoWidth,
            leftOffset: -50,
            top: videoHeight / 2 - 10,
          });
          console.log("adding right arrow");
        }
      }
    }

    window.myFunction = function (e) {
      e.preventDefault();
      try {
        console.log(e.path[0]);
        console.log("running my function");
        window.player.currentTime(10);
        window.passedScene = 1;
        window.player.play();
      } catch (err) {
        throw new Error(err.message);
      }
      return false;
    };

    // adding input fields
    console.log("adding input fields");
    if (this.props.video.scenes)
      for (let scene of this.props.video.scenes) {
        for (let ph of scene.placeholders) {
          //console.log("PH TYPE", ph.type);
          //console.log(ph);
          let start_time = scene.startTime + ph.start_time;
          let end_time = scene.startTime + ph.end_time;

          if (ph.type === "multiplechoice") {
            let idMultipleChoice = ph.placeholderid;
            console.log("FOUND MULTIPLECHOICE HERE,", ph);

            let nameMultipleChoice = ph.name;
            if (ph.data.hasOwnProperty("submit_button")) {
              let viewBox = ph.data.submit_button.viewBox || "";
              let points = ph.data.submit_button.points || "";
              let pos = ph.data.submit_button.pos;
              let elementid = ph.placeholderid + "-elementid";
              let answerid = ph.placeholderid + "-submitbutton";

              //let multipleChoiceContent = ph.data.answers[0].svg;
              let multipleChoiceContent =
                '<form class="multiplechoiceclass" name="' +
                nameMultipleChoice +
                '" id="' +
                idMultipleChoice +
                '">' +
                '<svg  style="cursor:pointer;z-index:1;position:fixed;height:' +
                pos.height +
                ";width:" +
                pos.width +
                ";left:" +
                pos.left +
                ";top:" +
                pos.top +
                ';" viewBox="' +
                viewBox +
                '" id="' +
                answerid +
                '" ><polygon id="' +
                elementid +
                '" points="' +
                points +
                '"   style="fill:lime;fill-opacity:0;stroke:green;stroke-width:45;stroke-opacity:0;fill-rule:nonzero;" onclick="window.handleMultipleChoiceClick(event);" /></svg>' +
                "</form>";

              overlays.push({
                align: "",
                start: start_time,
                end: end_time,
                content: multipleChoiceContent,
              });
              overlaysPos.push({
                answerid: answerid,
                elementid: elementid,
                placeholderid: ph.placeholderid,
                id: answerid,
                top: pos.top,
                left: pos.left,
                width: pos.width,
                height: pos.height,
              });
            }
            if (ph.data.answers && Array.isArray(ph.data.answers))
              for (let answer of ph.data.answers) {
                let answerid = answer.answerid;
                let elementid = answer.elementid;
                let idMultipleChoice = ph.placeholderid;
                if (answer.type === "svg") {
                  /*let svg =
                '<div id="testsvg"  ><svg id="testsvgsvg"   width="332" viewBox="0 0 332 167"   height="167" style="position:fixed;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="hidden"><text id="quizz_placeholder" opacity="0" x="0" y="0" dy="65">%%INTERACTIVE_VIDEO%</text><text id="quizz_placeholder" opacity="0" x="0" y="0" dy="65">%%FORM_CHECKBOX_TRUE%</text><g clip-path="url(#clip0)" transform="translate(-336 -125)"><rect id="quizz_answerbox" opacity="1" x="343.5" y="145.5" width="153" height="126" stroke="#000000" stroke-width="3" stroke-miterlimit="8" fill="none"/><path id="quizz_checked" opacity="0" d="M143.344 36.2656 128.734 21.6563 82.5 67.8906 36.2656 21.6563 21.6563 36.2656 67.8906 82.5 21.6563 128.734 36.2656 143.344 82.5 97.1094 128.734 143.344 143.344 128.734 97.1094 82.5Z" transform="matrix(1 0 -0 1.00606 337 125)"/><path  id="quizz_checkcorrect1" opacity="0" d="M514 200C514 158.579 548.25 125 590.5 125 632.75 125 667 158.579 667 200 667 241.421 632.75 275 590.5 275 548.25 275 514 241.421 514 200Z" fill="#548235" fill-rule="evenodd"/><path id="quizz_checkcorrect1" opacity="0" d="M630.883 145 573.255 221.068 545.567 181.462 536 194.193 572.805 247 582.485 234.427 640 158.202Z" fill="#F2F2F2" fill-rule="evenodd"/><path id="quizz_checkwrong1" opacity="0" d="M514 200C514 158.579 548.474 125 591 125 633.526 125 668 158.579 668 200 668 241.421 633.526 275 591 275 548.474 275 514 241.421 514 200Z" fill="#C00000" fill-rule="evenodd"/><path id="quizz_checkwrong1" opacity="0" d="M625 172.524 616.476 164 589.5 190.976 562.524 164 554 172.524 580.976 199.5 554 226.476 562.524 235 589.5 208.024 616.476 235 625 226.476 598.024 199.5Z" fill="#FFFFFF" fill-rule="evenodd"/></g></svg></div>';
              overlays.push({
                align: "",
                start: 0,
                end: 30,
                content: svg,
              });
              overlaysPos.push({
                id: "testsvgsvg",
                height: 100,
                width: 200,
                left: 800,
                top: 500,
              }); */
                  let multipleChoiceContent =
                    '<form class="multiplechoiceclass ' +
                    idMultipleChoice +
                    '" name="' +
                    nameMultipleChoice +
                    '" id="' +
                    idMultipleChoice +
                    '">' +
                    answer.svg +
                    "</form>";

                  overlays.push({
                    align: "",
                    start: start_time,
                    end: end_time,
                    content: multipleChoiceContent,
                  });
                  overlaysPos.push({
                    answerid: answerid,
                    elementid: elementid,
                    placeholderid: ph.placeholderid,
                    id: answerid,
                    top: answer.pos.top,
                    left: answer.pos.left,
                    width: answer.pos.width,
                    height: answer.pos.height,
                  });
                } else {
                  let viewBox = answer.viewBox || "";
                  let points = answer.points || "";

                  //let multipleChoiceContent = ph.data.answers[0].svg;
                  let multipleChoiceContent =
                    '<form class="multiplechoiceclass" name="' +
                    nameMultipleChoice +
                    '" id="' +
                    idMultipleChoice +
                    '">' +
                    '<svg  style="cursor:pointer;z-index:1;position:fixed;height:' +
                    answer.pos.height +
                    ";width:" +
                    answer.pos.width +
                    ";left:" +
                    answer.pos.left +
                    ";top:" +
                    answer.pos.top +
                    ';" viewBox="' +
                    viewBox +
                    '" id="' +
                    answerid +
                    '" ><polygon id="' +
                    elementid +
                    '" points="' +
                    points +
                    '"   style="fill:lime;fill-opacity:0;stroke:green;stroke-width:45;stroke-opacity:0;fill-rule:nonzero;" onclick="window.handleMultipleChoiceClick(event);" /></svg>' +
                    "</form>";

                  overlays.push({
                    align: "",
                    start: start_time,
                    end: end_time,
                    content: multipleChoiceContent,
                  });
                  overlaysPos.push({
                    answerid: answerid,
                    elementid: elementid,
                    placeholderid: ph.placeholderid,
                    id: answerid,
                    top: answer.pos.top,
                    left: answer.pos.left,
                    width: answer.pos.width,
                    height: answer.pos.height,
                  });
                }
                pausePositions.push({
                  end_time: scene.startTime + ph.data.pauseAt - 0.2,
                  sceneOrder: scene.sceneOrder,
                });
              }
          }

          if (ph.type === "textinput") {
            let idDiv = "textareadiv-" + ph.name;
            let idTextarea = ph.placeholderid;
            let nameTextarea = ph.name;
            let idForm = "form-" + ph.name;
            let idButton = "button-" + ph.name;

            let textareavalue = "";
            if (this.props.answers && Array.isArray(this.props.answers))
              for (let a of this.props.answers) {
                if (a.placeholderid === idTextarea) {
                  textareavalue = a.cache_value;
                }
              }
            let textInputContent =
              /*'<style type="text/css>#' +
              idDiv +
              ":disabled{background-color:#fff;}</style>" + */
              '<div id="' +
              idDiv +
              '" style="z-index:1;background-color:#fff;' +
              "cursor:pointer;" +
              'position:absolute;color:black;"><form class="textareaclass" name="' +
              idForm +
              '" id="' +
              idForm +
              '" onsubmit="return handleSubmitButton(event,)">' +
              '<textarea name="' +
              nameTextarea +
              '" id="' +
              idTextarea +
              '" ' +
              (!this.props.sessionid ? " disabled " : "") +
              'placeholder="Enter your text here" name="' +
              ph.name +
              '" rows="4" cols="50">' +
              "" +
              textareavalue +
              (!this.props.sessionid ? "TextInput is disabled for generic links, use a session link" : "") +
              "</textarea>" +
              '<input class="textareabutton" id="' +
              idButton +
              '" type="submit" value="Submit" name="' +
              idTextarea +
              '" style="position:fixed;">' +
              "</form>" +
              "</div>";
            overlays.push({
              align: "",
              start: start_time,
              end: end_time,
              content: textInputContent,
            });
            overlaysPos.push({
              id: idButton,
              top: ph.attributes.pos.top + ph.attributes.pos.height,
              left: ph.attributes.pos.left + ph.attributes.pos.width / 2,
              leftOffset: -26,
              bottomOffset: 100,
            });
            overlaysPos.push({
              id: idDiv,
              left: ph.attributes.pos.left,
              top: ph.attributes.pos.top,
              height: ph.attributes.pos.height,
              width: ph.attributes.pos.width,
            });
            overlaysPos.push({
              id: idTextarea,
              height: ph.attributes.pos.height,
              width: ph.attributes.pos.width,
            });
            pausePositions.push({ end_time: scene.startTime + ph.end_time - 2.5, sceneOrder: scene.sceneOrder });
          }
        }
      }

    // adding hotlinks
    if (this.state.hotlinkPhs.length > 0) {
      for (let l of this.state.hotlinkPhs)
        if (l.data.hasOwnProperty("linkTarget")) {
          let idPh = "hotlink-" + l.name;
          let content =
            '<a id="' +
            idPh +
            '" style="position:absolute;display:block;z-index:1;color:red;' +
            /*"height:" +
            Math.round(l.attributes.pos.height * adjustRatio) +
            "px !important;width:" +
            Math.round(l.attributes.pos.width * adjustRatio) +
            "px !important;left:" +
            Math.round(left + l.attributes.pos.left * adjustRatio) +
            "px !important;top:" +
            Math.round(top + l.attributes.pos.top * adjustRatio) +
            "px !important;" + */
            "cursor:pointer;" +
            (true /*this.props.debug*/ ? "border:1px dashed grey;" : "") +
            '" ';

          if (l.data.linkType === "external")
            content += "  onclick=\"window.open('" + l.link + "');videojs.pause(); \"";
          //if (l.data.linkType === "internal") content += ' href="' + l.link + '" ';
          if (l.data.linkType === "goto")
            content +=
              ' onclick="window.passedScene=' +
              l.goto_scene +
              ";window.prevScene=" +
              l.sceneNumber +
              ";window.prevSceneStartTime=" +
              l.sceneStartTime +
              ";videojs.currentTime(" +
              parseFloat(l.goto_time) +
              ');videojs.play(); "';
          if (l.data.linkTarget === "back") {
            content +=
              ' onclick="window.passedScene=window.prevScene-1' +
              ';videojs.currentTime(parseFloat(window.prevSceneStartTime));"';
          }

          content += "> </a>";
          overlays.push({
            align: "",
            start: l.start_time,
            end: l.end_time,
            content: content,
          });
          overlaysPos.push({
            id: idPh,
            height: l.attributes.pos.height,
            width: l.attributes.pos.width,
            left: l.attributes.pos.left,
            top: l.attributes.pos.top,
          });
        }
    }
    //console.log("updating overlays", overlays.count, overlaysPos.count);
    overlays.push({
      align: "",
      start: 0,
      end: 1,
      content: "<div id='mutationobserver-watch'></div>",
    });

    let touchArea = document.getElementById("vjs_video_3_html5_api");
    if (process.browser) {
      whenAvailableZingTouch("ZingTouch.Region", function (t) {
        // do something
        let observer = new MutationObserver(callbackMutation);
        var targetNode = document.getElementById("vjs_video_3");
        try {
          observer.observe(targetNode, { childList: true, attributes: false, subtree: false });
          //this.props.setOverlaysSet(true);
        } catch (err) {
          console.log("observer failed, no overlays");
          this.handleResize();
        }

        //

        let myRegion = new ZingTouch.Region(touchArea);
        myRegion.bind(touchArea, "tap", function (e) {
          console.log("GLOBAL TAB", e.detail);
          let currentTime = that.player.currentTime();
          let disablePlay = false;
          let disablePause = false;
          for (let s of that.state.scenes) {
            if (currentTime > s.startTime && currentTime < s.endTime) {
              if (s.hasOwnProperty("disablePlay")) disablePlay = s.disablePlay;
              if (s.hasOwnProperty("disablePause")) disablePause = s.disablePause;
            }
          }
          if (!disablePlay && window.player.paused()) window.player.play();
          else {
            if (!disablePause) window.player.pause();
            if (that.state.hidePlayButton) that.hidePlayButton();
          }
          e.preventDefault();
        });
      });
    }

    if (process.browser) {
      const callbackMutation = function overlayWatch(mutationsList, observer) {
        for (const mutation of mutationsList) {
          if (mutation.type === "childList") {
            for (let a of mutation.addedNodes)
              for (let c of a.children)
                if (c.id === "mutationobserver-watch") {
                  observer.disconnect();

                  console.log("OVERLAY READY");
                  for (let pos of that.state.overlaysPos) {
                    let touchArea = document.getElementById(pos.id);
                    let myRegion = new ZingTouch.Region(touchArea);

                    myRegion.bind(touchArea, "tap", function (e) {
                      console.log("TAP", pos.aswerid, pos.elementid, pos.placeholderid, e.detail);
                      e.preventDefault();
                      if (pos.answerid && pos.elementid && pos.placeholderid)
                        window.handleOverlayProcessing(pos.answerid, pos.elementid, pos.placeholderid);
                    });
                  }

                  that.handleResize();
                  //that.props.setOverlaysSet(true);
                }
          }
        }
      };
    }

    this.player.overlay({
      debug: this.props.debug,
      overlays: overlays,
    });
    let that = this;

    this.setState({ overlaysPos: overlaysPos, pausePositions: pausePositions });

    //console.log("updating overlays 2");
    /* const element = document.getElementById("textarea-slide1-element5");
    if (element) element.focus();
    else console.log("element not ready");
    //let ids = document.querySelectorAll("*[id]");
    let elements = document.querySelectorAll('*[id^="textarea"]');
    for (let elem of elements) {
      elem.addEventListener("click", function (event) {
        console.log("event click", event);
        event.preventDefault();
      }); */
    /*elem.addEventListener("touchstart", function (event) {
        console.log("event touchstart", event);
        //event.preventDefault();
      });*/
    /*
      elem.addEventListener(
        "focus",
        function (event) {
          console.log("event focus", event);
          event.preventDefault();
          const id = elem.id;
          this.handleResize();
          const element = document.getElementById(id).focus();
          console.log("FOUND ELEMENT", element);
        }.bind(this)
      ); */
    //}
    //console.log("ids", ids);
  }

  //onclick=\"window.open('" + l.link + "');console.log('TEST'); alert(window.videoNode.testCode());\""
  componentDidUpdate(prevProps) {
    //console.log("COMPONENT DID UPDATE");

    /*let playerStyle = window.getComputedStyle(this.player.el());
    let parentStyle = window.getComputedStyle(this.player.el().parentNode);
    console.log("height", playerStyle.getPropertyValue("height"));
    let width2 = parentStyle.getPropertyValue("width");
    let height2 = parentStyle.getPropertyValue("height");
    console.log("width new", width2);
    console.log("height", height2);
    console.log("currentWidth", this.player.currentWidth());
    console.log("currentHeight", this.player.currentHeight()); */

    //console.log("height", window.getComputedStyle(this.player.el()).height.valueOf());
    //if (prevProps.video !== this.props) {
    if (this.props.video && this.props.video.scenes && !this.state.dynamicElementsUpdated) {
      //console.log("PREVPROPS ARE OLD");

      var customModals = [];
      let hotlinkPhs = [];
      // collect scenes
      let scenes = [];
      if (this.props.video.attributes && this.props.video.attributes.pauseAfterEachScene) {
        this.setState({ hidePlayButton: true });
        //console.log("setting hidePlayButton true");
      }
      if (this.props.video.scenes) {
        //console.log("found scenes");
        let sceneNumber = 0;
        for (var s of this.props.video.scenes) {
          if (s.global === 0) {
            let scene = {};
            sceneNumber += 1;
            scene.number = sceneNumber;
            scene.name = s.name;
            scene.startTime = s.startTime;
            scene.endTime = s.endTime;

            if (s.attributes && s.attributes.pauseAfter) scene.pauseAfter = true;
            if (s.pauseAfter) scene.pauseAfter = true;
            if (s.attributes && s.attributes.gotoSlide) scene.gotoSlide = s.attributes.gotoSlide;
            if (s.gotoSlide) scene.gotoSlide = s.gotoSlide;

            for (ph of s.placeholders) {
              if (ph.type === "multiplechoice") {
                scene.disablePause = true;
                scene.disablePlay = true;
              }
            }
            scenes.push(scene);
          }
        }
        let refscenes = scenes;
        for (var s of scenes) {
          if (s.gotoSlide) {
            //console.log("scene", s);
            for (var sc of refscenes) {
              if (sc.number === s.gotoSlide) {
                s.goto_time = sc.startTime + 0.1;
                console.log("goto time", s.goto_time);
              }
            }
          }
        }

        //console.log("scenes created");
      }

      // add hotlinks
      if (this.props.video.scenes) {
        let sceneNumber = 0;
        for (var scene of this.props.video.scenes) {
          if (scene.global === 0) sceneNumber += 1;
          for (var ph of scene.placeholders) {
            if (ph.type === "hotlink" && !(this.props.settings && this.props.settings.nolinks)) {
              let newPh = JSON.parse(JSON.stringify(ph));
              newPh.start_time = scene.startTime + newPh.start_time;
              newPh.end_time = scene.startTime + newPh.end_time;
              newPh.sceneStartTime = scene.startTime;
              newPh.sceneNumber = sceneNumber;

              if (newPh.data.linkType === "internal") {
                let targetSceneNumber = null;
                if (newPh.data.linkTarget === "next") targetSceneNumber = sceneNumber + 1;
                if (newPh.data.linkTarget === "previous") targetSceneNumber = newPh.sceneNumber - 1;
                if (newPh.data.linkTarget === "last") targetSceneNumber = scenes.length;
                if (newPh.data.linkTarget === "first") targetSceneNumber = 1;
                if (typeof newPh.data.linkTarget === "number") targetSceneNumber = newPh.data.linkTarget;

                for (let s of scenes)
                  if (s.number === targetSceneNumber) {
                    newPh.goto_time = s.startTime + 0.1;
                    newPh.goto_scene = targetSceneNumber;
                    newPh.data.linkType = "goto";
                  }
              }
              if (newPh.data.linkType === "external") newPh.link = newPh.data.linkTarget;
              hotlinkPhs.push(newPh);
            }
            this.addPlaceholder(ph);
          }
        }
      }

      // test overlay

      // create chapters
      /*
      if (scenes.length > 0) {
        console.log("create scenes");
        var file;
        var data = [];
        data.push("WEBVTT");
        for (let s of scenes) {
          data.push(
            "\n\n" +
              s.number +
              "\n" +
              moment.utc(1000 * s.startTime).format("HH:mm:ss.SSS") +
              " --> " +
              moment.utc(1000 * s.endTime).format("HH:mm:ss.SSS") +
              "\n" +
              s.name
          );
        }

        var properties = { type: "text/plain" }; // Specify the file's mime-type.
        try {
          // Specify the filename using the File constructor, but ...
          file = new File(data, "file.txt", properties);
        } catch (e) {
          console.log("new file does not work");
          // ... fall back to the Blob constructor if that isn't supported.
          file = new Blob(data, properties);
        }
        var url = URL.createObjectURL(file);
        this.player.addRemoteTextTrack(
          {
            kind: "chapters",
            src: url,
          },
          false
        );
        console.log(this.player.textTracks()); // print out => 0
        console.log("remote", this.player.remoteTextTracks().length); // print out =>  0
      } */

      if (!process.browser) return <div>video not ready</div>;

      // navigate to chapters
      if (this.props.navigation && this.props.navigation.sceneNumber) {
        for (let s of scenes)
          if (s.number === this.props.navigation.sceneNumber) this.player.currentTime(parseFloat(s.startTime));
      }
      this.setState(
        {
          scenes: scenes,
          customModals: customModals,
          hotlinkPhs: hotlinkPhs,
          dynamicElementsUpdated: true,
        },
        this.setOverlays
      );
      this.savePlaceholders();
    }
  }

  organizePlaceholders() {}

  onClosePlaceholder(node, placeholder, resultData = null) {
    placeholder.attempted = true;
    this.updatePlaceholder(placeholder);
    if (placeholder.afterInteraction) {
      if (typeof placeholder.afterInteraction === "function") {
        placeholder.afterInteraction(resultData);
      }
    }

    ReactDOM.unmountComponentAtNode(node);
    node.remove();
    if (this.state.controlsHidden) {
      this.showControls();
    }
    if (this.state.playBtnHidden) {
      this.showPlayButton();
    }
    if (this.state.player_locked) {
      this.unlockPlayer(() => {
        this.state.player.play();
      });
    } else {
      this.state.player.play();
    }
    let newPlaceholders = [];
    for (var i = this.visiblePlaceholders.length - 1; i >= 0; i--) {
      if (this.visiblePlaceholders[i].placeholder.id != placeholder.id) {
        newPlaceholders.push(this.visiblePlaceholders[i]);
      }
    }
    this.visiblePlaceholders = newPlaceholders;
  }

  playerHeight() {
    return this.state.player.el_.clientHeight;
  }

  playerWidth() {
    return this.state.player.el_.clientWidth;
  }

  showPlaceholder(placeholder, player) {
    console.log("DEBUG, showplaceholder", placeholder);
    if (placeholder.type === "h5p") {
      if (placeholder.attributes && placeholder.attributes.player_action) {
        if (placeholder.attributes.player_action === "pause") {
          player.pause();
          this.hideControls();
          this.hidePlayButton();
          this.lockPlayer();
        } else if (placeholder.attributes.player_action === "pause_withcontrols") {
          player.pause();
          // this.hideControls();
          this.hidePlayButton();
          // this.lockPlayer();
        }
      }

      var node = document.createElement("div"); // Create a <li> node
      node.style.background = "transparent";
      node.style.width = "100%";
      node.style.height = "100%";
      if (placeholder.attributes && placeholder.attributes.blur_video === "true") {
        node.style.background = "rgba(0,0,0,0.8)";

        node.style.position = "absolute";
        node.style.top = 0;
      }
      ReactDOM.render(
        <ModalDialog2
          pauseMedia={this.pauseMedia}
          playerHeight={this.playerHeight}
          playerWidth={this.playerWidth}
          placeholder={placeholder}
          dtnode={node}
          onClose={this.onClosePlaceholder}
          play_with_controls={this.props.play_with_controls}
        />,
        node
      );
      this.state.player.el_.appendChild(node);
      if (!placeholder.attributes.after_display_duration) {
        if (placeholder.attributes.duration) {
          setTimeout(() => {
            this.onClosePlaceholder(node, placeholder);
          }, placeholder.attributes.duration);
        }
      }

      this.visiblePlaceholders.push({
        placeholder: placeholder,
        node: node,
      });
    }
  }

  playertimeUpdateCb(e, ref) {
    // if (this.state.videoStopped){
    //   return;
    // }
    //console.log(ref.currentTime());
    var ct = Math.floor(ref.currentTime());
    if (ct === this.passedSeconds) {
      return;
    }

    this.passedSeconds = ct;
    var placeholders = this.getPlaceholderByTime(ct);
    if (placeholders) {
      for (var i = placeholders.length - 1; i >= 0; i--) {
        this.showPlaceholder(placeholders[i], ref);
      }
    }
  }

  pauseMedia() {
    this.state.player.pause();
  }

  hideControls() {
    if (this.props.play_with_controls) {
      return;
    }
    var elements = document.getElementsByClassName("vjs-control-bar");
    for (var i = elements.length - 1; i >= 0; i--) {
      elements[i].style.display = "none";
    }
    this.setState({ controlsHidden: true });
  }

  showControls() {
    if (this.props.play_with_controls) {
      return;
    }
    var elements = document.getElementsByClassName("vjs-control-bar");
    for (var i = elements.length - 1; i >= 0; i--) {
      elements[i].style.display = "flex";
    }
    this.setState({ controlsHidden: false });
  }

  hidePlayButton() {
    var elements = document.getElementsByClassName("vjs-big-play-button");
    for (var i = elements.length - 1; i >= 0; i--) {
      elements[i].style.display = "none";
    }
    this.setState({ playBtnHidden: true });
  }

  showPlayButton() {
    var elements = document.getElementsByClassName("vjs-big-play-button");
    for (var i = elements.length - 1; i >= 0; i--) {
      elements[i].removeAttribute("style");
    }
    this.setState({ playBtnHidden: false });
  }

  lockPlayer() {
    if (this.props.play_with_controls) return true;
    this.setState({ player_locked: true });
  }

  unlockPlayer(cb = null) {
    if (this.props.play_with_controls) return true;
    this.setState({ player_locked: false }, cb);
  }

  seekPreventer(event, ref) {
    var ct = ref.currentTime();
    var skipped = this.anyLockedPlaceholdersSkipped(ct);
    if (skipped) {
      var time = skipped.time;
      if (time - 1 <= 0) {
        time = 0;
      } else {
        time = time - 0.5;
      }
      this.state.player.currentTime(time);
      // this.state.player.pause();
    }
  }

  checkTime() {
    //console.log("window passed scene", window.passedScene);
    //console.log("buffered", this.player.bufferedEnd());
    if (!this.state.resizedOnceAfterStart && this.player.currentTime() > 0.01 && this.player.currentTime() < 0.5) {
      this.setState({ resizedOnceAfterStart: true }, () => {
        this.handleResize();
      });
    }
    for (let p of this.state.pausePositions) {
      if (
        !p.paused &&
        this.player.currentTime() >= parseFloat(p.end_time) &&
        this.player.currentTime() < parseFloat(p.end_time + 0.2)
        /*&&
        p.sceneOrder > window.passedScene */
      ) {
        p.paused = true;
        this.setState({ pausePositions: this.state.pausePositions, hidePlayButton: true }, () => {
          this.hidePlayButton();
          this.player.pause();

          console.log("pausing at end of placeholder", p.end_time);
        });
      }
      // reset paused
      if (p.paused && this.player.currentTime() >= parseFloat(p.end_time + 1)) {
        p.paused = false;
        this.setState({ pausePositions: this.state.pausePositions }, console.log("reset ph pausing", p.end_time));
      }
    }

    for (let scene of this.state.scenes) {
      // pause after each scene
      if ((this.props.video.attributes.pauseAfterEachScene || scene.pauseAfter) && scene.number >= window.passedScene)
        if (this.player.currentTime() >= parseFloat(scene.endTime - 0.3) && this.player.currentTime() < scene.endTime) {
          console.log("pausing at end of scene");
          window.passedScene = scene.number;
          this.player.pause();

          //this.passedScene = scene.number;
          this.player.currentTime(parseFloat(scene.endTime - 0.1));
          // this.hideControls();
          this.hidePlayButton();
          // this.lockPlayer();
        }
      if (scene.gotoSlide && scene.number >= window.passedScene)
        if (
          this.player.currentTime() >= parseFloat(scene.endTime - 0.5) &&
          this.player.currentTime() < scene.endTime + 0.1
        ) {
          console.log("skipping to slide", scene.gotoSlide, scene.goto_time);
          window.passedScene = scene.gotoSlide;
          //this.passedScene = scene.number;

          this.player.currentTime(parseFloat(scene.goto_time));
          // this.hideControls();
          // this.lockPlayer();
        }
      // pause after placeholders
      /*for (let ph of scene.placeholders) {
        if(ph.attributes.pauseAfter) {}
      } */

      // update passedScene
      if (
        this.player.currentTime() > scene.startTime + 0.4 &&
        this.player.currentTime() < parseFloat(scene.endTime) &&
        scene.number - 1 > window.passedScene
      )
        window.passedScene = scene.number - 1;
    }

    this._frame = window.requestAnimationFrame(this.checkTime.bind(this));

    /*let framesPerSecond = 25;

    if (this.player) {
      console.log(this.player.currentTime());
      let that = this;
      setTimeout(
        function (that) {
          window.requestAnimationFrame(that.checkTime());

          // animating/drawing code goes here
        }.bind(this),
        1000 / framesPerSecond
      );
    } */
  }

  startCheckTime() {
    if (this.player) {
      this._frame = window.requestAnimationFrame(this.checkTime.bind(this));
    }
  }

  endCheckTime() {
    window.cancelAnimationFrame(this._frame);
  }

  afterPlaybackEnds(ev) {
    this.setState({ videoStopped: true });
    for (var i = this.visiblePlaceholders.length - 1; i >= 0; i--) {
      var n = this.visiblePlaceholders[i].node;
      ReactDOM.unmountComponentAtNode(n);
    }
    // ReactDOM.unmountComponentAtNode(node)
  }
  /*
  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.videoJsOptions === nextProps.videoJsOptions) {
      return false;
    } else {
      return true;
    }
  } */

  componentDidMount() {
    /* (async function () {
      const fpid = await guid();
      window.fpid = fpid;
      //console.log("FPID: " + fpid); // Now you can use it
      //document.cookie = `fpid=${encodeURIComponent(fpid)}`;
    })(); */

    window.passedScene = 0;
    var projectid = this.props.projectid;
    if (!projectid) console.warn("no projectid");
    window.addEventListener("resize", this.handleResize);

    var that = this;
    /*console.log("ANALYTICS")
    window.gtag('config', 'UA-73128979-3',{
      'custom_map': {
          'dimension1': 'projectid'
      }
    }); */
    if (!window.videoNode) console.log("video node not ready");

    if (window.videoNode) {
      this.player = videojs(window.videoNode, this.props.videoJsOptions, async function onPlayerReady() {
        var player = this;
        player.on("loadeddata", function () {
          that.setState({ videoloadeddata: true });
        });

        player.log.level("all");
        //console.log("SRC", player.currentSrc());
        player.volume(0.7);

        await import("videojs-landscape-fullscreen");
        player.landscapeFullscreen({
          fullscreen: {
            enterOnRotate: true,
            alwaysInLandscapeMode: false,
            iOS: true,
          },
        });
        if (that.props && that.props.video && that.props.video.scenes && that.props.video.scenes.length > 0) {
          console.log("create scenes", that.props.video.scenes.length);
          var file;
          var data = [];
          data.push("WEBVTT");
          for (let [idx, s] of that.props.video.scenes.entries()) {
            if (s.global === 0)
              data.push(
                "\n\n" +
                  idx +
                  "\n" +
                  moment.utc(1000 * s.startTime).format("HH:mm:ss.SSS") +
                  " --> " +
                  moment.utc(1000 * s.endTime).format("HH:mm:ss.SSS") +
                  "\n" +
                  s.name
              );
          }
          //console.log("DEBUGSCENES", JSON.stringify(data));

          try {
            // Specify the filename using the File constructor, but ...
            //file = new Blob(data, { type: "text/plain" });
            file = new File(data, "file.txt", { type: "text/plain" });
          } catch (e) {
            console.log("new file does not work");
            // ... fall back to the Blob constructor if that isn't supported.
            file = new Blob(data, { type: "text/plain" });
          }
          var url = URL.createObjectURL(file);
        }
        console.log("URL", url);
        player.addRemoteTextTrack(
          {
            kind: "chapters",
            src: url,
          },
          false
        );

        if (that.props && that.props.video && that.props.video.scenes) {
          let gotcaptionshowing = false;
          let gotcaptionhiding = false;

          var data = [];

          var idx = 0;
          data.push("WEBVTT ");
          data.push("");
          for (var i = 0; i < that.props.video.scenes.length; i++) {
            var s = that.props.video.scenes[i];
            if (s.captionbase64 && s.captionbase64.length > 0) {
              if (s.caption_confidence == 1) {
                gotcaptionshowing = true;
              } else {
                gotcaptionhiding = true;
              }
              var scenecaption = JSON.parse(Buffer.from(s.captionbase64, "base64").toString("utf8"));
              for (var j = 0; j < scenecaption.length; j++) {
                idx++;
                data.push("");
                data.push("");
                data.push(
                  "" +
                    moment.utc(1000 * scenecaption[j].start).format("HH:mm:ss.SSS") +
                    " --> " +
                    moment.utc(1000 * scenecaption[j].end).format("HH:mm:ss.SSS")
                );
                data.push(scenecaption[j].sentence);
              }
            } else if (s.caption && s.caption.length > 0) {
              if (s.caption_confidence == 1) {
                gotcaptionshowing = true;
              } else {
                gotcaptionhiding = true;
              }
              for (var j = 0; j < s.caption.length; j++) {
                idx++;
                data.push("");
                data.push("");
                data.push(
                  "" +
                    moment.utc(1000 * s.caption[j].start).format("HH:mm:ss.SSS") +
                    " --> " +
                    moment.utc(1000 * s.caption[j].end).format("HH:mm:ss.SSS")
                );
                data.push(s.caption[j].sentence);
              }
            }
          }

          if (that.props.hideCaption) {
            gotcaptionhiding = true;
            gotcaptionshowing = false;
          }

          let captionstring = Buffer.from(data.join("\n")).toString("base64");
          let caption = data.join("\n");
          console.log(caption);
          captionstring = "data:image/png;base64, " + captionstring;

          if (gotcaptionshowing) {
            player.addRemoteTextTrack(
              {
                kind: "captions",
                src: captionstring,
                label: "Caption",
                mode: "showing",
                default: "",
              },
              false
            );
          }
          if (gotcaptionhiding) {
            player.addRemoteTextTrack(
              {
                kind: "captions",
                src: captionstring,
                label: "Caption",
              },
              false
            );
          }

          that.setState({
            gotcaptionshowing: gotcaptionshowing,
            gotcaptionhiding: gotcaptionhiding,
            captionstring: captionstring,
          });
          if (false && (gotcaptionhiding || gotcaptionshowing)) {
            let captionDownloadButton = player.controlBar.addChild("button");
            let captionDownloadButtonDom = captionDownloadButton.el();
            captionDownloadButtonDom.innerHTML = "Download caption";
            captionDownloadButtonDom.style.order = 3;
            captionDownloadButtonDom.style.width = "100px";
            //captionDownloadButtonDom.classList.add("participant-controls");
            captionDownloadButtonDom.onclick = function () {
              var win = window.open();
              win.document.write(
                '<iframe src="data:text/plain,' +
                  caption +
                  '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
              );
            };
            whenAvailableZingTouch("ZingTouch.Region", function (t) {
              let myRegion2 = new ZingTouch.Region(captionDownloadButtonDom);
              myRegion2.bind(captionDownloadButtonDom, "tap", function (e) {
                console.log("DOWNLOAD CAPTION TAB", e.detail);
                var win = window.open();
                win.document.write(
                  '<iframe src="data:text/plain,' +
                    caption +
                    '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
                );
              });
            });
          }
          let automatevideoButton = player.controlBar.addChild("button");
          let automatevideoButtonDom = automatevideoButton.el();
          automatevideoButtonDom.innerHTML = "<span>automate<br>&nbsp;&nbsp;&nbsp;.video</span>";
          automatevideoButtonDom.style.order = 3;
          automatevideoButtonDom.style.width = "100px";
          //captionDownloadButtonDom.classList.add("participant-controls");
          automatevideoButtonDom.onclick = function () {
            window.open("https://automate.video/pages/ai-virtual-presenter");
          };
          whenAvailableZingTouch("ZingTouch.Region", function (t) {
            let myRegion2 = new ZingTouch.Region(automatevideoButtonDom);
            myRegion2.bind(automatevideoButtonDom, "tap", function (e) {
              window.open("https://automate.video/pages/ai-virtual-presenter");
            });
          });
        }

        window.player = this;
        //console.log("video player ready");
        //that.props.setPlayerReady(true);
        if (
          that.props &&
          that.props.video &&
          that.props.video.attributes.hasOwnProperty("lockPlayer") &&
          that.props.video.attributes.lockPlayer
        ) {
          player.controlBar.progressControl.el().style.display = "none";
          let parControls = document.getElementsByClassName("vjs-chapters-button");
          console.log("parControls", parControls);
          for (let p of parControls) {
            p.style.display = "none";
          }
        } else {
          let nextSceneImage = player.controlBar.addChild("Component", {}, 16);
          let prevSceneImage = player.controlBar.addChild("Component", {}, 16);
          nextSceneImage.addClass("next-scene-image");
          prevSceneImage.addClass("prev-scene-image");
          let prevSceneDom = prevSceneImage.el();
          let nextSceneDom = nextSceneImage.el();
          prevSceneDom.style.marginRight = "10px";

          function prevScene(that, player) {
            if (that.props && that.props.video && that.props.video.scenes && that.props.video.scenes.length > 0) {
              let currentTime = window.player.currentTime();

              let currentScene = null;
              for (let [idx, scene] of that.props.video.scenes.entries())
                if (scene.global === 0)
                  if (currentTime > parseFloat(scene.startTime) && currentTime < parseFloat(scene.endTime))
                    currentScene = idx;
              if (Number.isInteger(currentScene)) {
                for (let [idx, scene] of that.props.video.scenes.entries())
                  if (idx === currentScene - 1) {
                    window.player.currentTime(parseFloat(scene.startTime));
                    window.player.play();
                    window.passedScene = currentScene - 1;
                  } else if (idx === 0) {
                    window.player.currentTime(parseFloat(scene.startTime));
                    window.player.play();
                    window.passedScene = currentScene - 1;
                  }
              }
            }
          }

          prevSceneDom.onclick = function () {
            prevScene(that, player);
          };

          whenAvailableZingTouch("ZingTouch.Region", function (t) {
            let prevSceneRegion = new ZingTouch.Region(prevSceneDom);
            prevSceneRegion.bind(prevSceneDom, "tap", function (e) {
              //e.preventDefault();
              prevScene(that, player);
            });
          });

          function nextScene(that, player) {
            if (that.props && that.props.video && that.props.video.scenes && that.props.video.scenes.length > 0) {
              let currentTime = window.player.currentTime();
              let currentScene = null;
              for (let [idx, scene] of that.props.video.scenes.entries())
                if (scene.global === 0)
                  if (currentTime > parseFloat(scene.startTime) && currentTime < parseFloat(scene.endTime))
                    currentScene = idx;
              if (Number.isInteger(currentScene)) {
                if (currentScene < that.state.scenes.length) {
                  for (let [idx, scene] of that.props.video.scenes.entries())
                    if (idx === currentScene + 1) {
                      window.player.currentTime(parseFloat(scene.startTime));
                      window.player.play();
                      window.passedScene = currentScene;
                    }
                }
              }
            }
          }

          nextSceneDom.onclick = function () {
            nextScene(that, player);
          };

          whenAvailableZingTouch("ZingTouch.Region", function (t) {
            let nextSceneRegion = new ZingTouch.Region(nextSceneDom);
            nextSceneRegion.bind(nextSceneDom, "tap", function (e) {
              //e.preventDefault();

              nextScene(that, player);
            });
          });
        }

        if (that.props.linkid && !that.props.anonymousUser) {
          var myButton = player.controlBar.addChild("button");
          var resetButton = player.controlBar.addChild("button");
          var downloadButton = player.controlBar.addChild("button");

          // There are many functions available for button component
          // like below mentioned in this docs
          // https://docs.videojs.com/button.
          // You can set attributes and clasess as well.

          // Getting html DOM
          var myButtonDom = myButton.el();
          var resetButtonDom = resetButton.el();
          let downloadButtonDom = downloadButton.el();
          // Since now you have the html dom element
          // you can add click events

          // Now I am setting the text as you needed.
          let participant = "";
          if (that.props.userSettings && that.props.userSettings.hasOwnProperty("participantid"))
            participant = "Participant: <strong>" + that.props.userSettings.participantid + "</strong>";
          myButtonDom.innerHTML = participant;
          myButtonDom.style.order = 1;
          myButtonDom.style.width = "300px";
          myButtonDom.style.textAlign = "right";
          myButtonDom.classList.add("participant-controls");

          resetButtonDom.innerHTML = "Not correct? Click to clear all";
          resetButtonDom.style.order = 2;
          resetButtonDom.style.width = "110px";
          resetButtonDom.classList.add("participant-controls");

          resetButtonDom.onclick = function () {
            try {
              //localStorage.clear();
              localStorage.removeItem(that.props.linkid + "_usersettings");
              localStorage.removeItem(that.props.linkid + "_answers");
              window.location.reload(true);
            } catch (err) {
              console.error("can not clear localStorage");
            }
          };

          let myRegion = new ZingTouch.Region(resetButtonDom);
          myRegion.bind(resetButtonDom, "tap", function (e) {
            console.log("RESETBUTTON TAB", e.detail);
            try {
              //localStorage.clear();
              localStorage.removeItem(that.props.linkid + "_usersettings");
              localStorage.removeItem(that.props.linkid + "_answers");
              window.location.reload(true);
            } catch (err) {
              console.error("can not clear localStorage");
            }
          });

          downloadButtonDom.innerHTML = "Download answers";
          downloadButtonDom.style.order = 3;
          downloadButtonDom.style.width = "100px";
          downloadButtonDom.classList.add("participant-controls");
          downloadButtonDom.onclick = function () {
            that.props.createAnswersPdf();
          };

          let myRegion2 = new ZingTouch.Region(downloadButtonDom);
          myRegion2.bind(downloadButtonDom, "tap", function (e) {
            console.log("DOWNLOADBUTTON TAB", e.detail);
            that.props.createAnswersPdf();
          });
        }
        /*
        // add button
        var videoJsButtonClass = videojs.getComponent("Button");
        var concreteButtonClass = videojs.extend(videoJsButtonClass, {
          // The `init()` method will also work for constructor logic here, but it is
          // deprecated. If you provide an `init()` method, it will override the
          // `constructor()` method!
          constructor: function () {
            videoJsButtonClass.call(this, player);
          }, // notice the comma

          handleClick: function () {
            // Do your stuff
          },
        });

        var concreteButtonInstance = player.controlBar.addChild(new concreteButtonClass());
        concreteButtonInstance.addClass("vjs-testbutton"); */

        if (!window.standaloneVideo && player.analytics)
          player.analytics({
            linkid: that.props.linkid,
            sessionid: that.props.sessionid,
            mode: "GTAG",
            customDimensions: {
              dimension1: projectid,
            },
            events: [
              {
                name: "play",
                label: "video play",
                action: "play",
              },
              {
                name: "pause",
                label: "video pause",
                action: "pause",
              },
              {
                name: "ended",
                label: "video ended",
                action: "ended",
              },
              {
                name: "fullscreenchange",
                label: {
                  open: "video fullscreen open",
                  exit: "video fullscreen exit",
                },
                action: "fullscreen change",
              },
              {
                name: "volumechange",
                label: "volume changed",
                action: "volume changed",
              },
              {
                name: "resize",
                label: "resize",
                action: "resize",
              },
              {
                name: "error",
                label: "error",
                action: "error",
              },
              {
                name: "resolutionchange",
                action: "resolution change",
              },
              {
                name: "timeupdate",
                action: "time updated",
              },
            ],
          });
        else console.error("analytics not registered");
        that.setState({ player: player });
      });
    }
    window.videojs = this.player;

    var placeholders = this.getPlaceholders();
    let markers = [];
    for (var i = placeholders.length - 1; i >= 0; i--) {
      var p = placeholders[i];
      if (p.start_time) {
        markers.push({ time: p.start_time, text: "H5P" });
      }
    }

    this.player.markers({
      markerStyle: {
        width: "9px",
        "border-radius": "10%",
        "background-color": "orange",
      },
      markers: markers,
    });

    var customModals = this.state.customModals;
    var that = this;
    this.player.on("timeupdate", function (e) {
      if (that.props.play_with_controls) {
        if (!that.state.controlBarOnTop) {
          //console.log("enabling controlbar");
          /**
           * Enables the control bar and makes sure it stays on top
           */
          var elements = document.getElementsByClassName("vjs-control-bar");

          for (var i = elements.length - 1; i >= 0; i--) {
            //console.log(elements[i]);
            elements[i].style.zIndex = 10;
          }
          that.setState({ controlBarOnTop: true });
        }
      }
      that.playertimeUpdateCb(e, this);
    });

    this.player.on("play", function () {
      console.log("video playing  ");
      that.startCheckTime();

      this.setState({ videoStopped: false });
      if (that.state.player_locked) {
        this.pause();
      } else {
      }
    });
    /*
    this.player.on("touchend", function () {
      console.log("touchend");
      if (this.paused()) this.play();
      else {
        this.pause();
        if (that.state.hidePlayButton) that.hidePlayButton();
      }
    }); */
    this.player.on("loadedmetadata", function () {
      var player = this;
      var tracks = player.textTracks();
      var track = tracks[0];
      if (that.props && that.props.showNotes) {
        for (var i = 0; i < tracks.length; i++) {
          var t = tracks[i];
          if (t.kind === "chapters") {
            track = tracks[i];
          }
        }
        track.on("cuechange", (e) => {
          //console.log(track.activeCues);
          var activeCues = track.activeCues;
          if (activeCues && activeCues.cues_ && activeCues.cues_.length > 0) {
            var cueId = activeCues.cues_[0].id;
            window.passedScene = parseInt(cueId);
            var note = "";
            if (activeCues.cues_[0].text) {
              note = "[" + activeCues.cues_[0].text + "] ";
            }
            console.log(that.props.video.scenes);
            var scenes = that.props.video.scenes;
            var gotNotes = false;
            if (scenes[parseInt(cueId)].notes) {
              note += scenes[parseInt(cueId)].notes;
              gotNotes = true;
            }

            console.log("cue", cueId + note);
            if (gotNotes) {
              that.setState({ currentCues: note, displayNotes: { display: "block" } });
            } else {
              that.setState({ currentCues: note, displayNotes: { display: "none" } });
            }
          } else {
            that.setState({ displayNotes: { display: "none" } });
          }
        });
      } else {
        for (var i = 0; i < tracks.length; i++) {
          var t = tracks[i];
          if (t.kind === "chapters") {
            track = tracks[i];
          }
        }
        track.on("cuechange", (e) => {
          var activeCues = track.activeCues;
          if (activeCues && activeCues.cues_ && activeCues.cues_.length > 0) {
            var cueId = activeCues.cues_[0].id;
            window.passedScene = parseInt(cueId);
          }
        });
      }
    });

    this.player.on("seeking", function (event) {
      that.seekPreventer(event, this);
    });

    this.player.on("seeked", function (event) {
      that.seekPreventer(event, this);
    });

    this.player.on(
      "pause",
      function () {
        //console.log("video paused");
        //console.log("buffered", this.player.bufferedEnd());
        this.setState({ videoStopped: true });
        that.endCheckTime();
      }.bind(this)
    );

    this.player.on("ended", function (event) {
      // console.log("Placeholders => ", that.state.placeholders)
      that.unlockPlayer();
      that.afterPlaybackEnds(event);
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    //console.log("video player will unmount");
    this.endCheckTime();
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  swipeEvents(eventData) {
    // console.log("EventDATA", eventData);
    //if (eventData.event.cancelable) eventData.preventDefault();

    if (
      this.props &&
      this.props.video &&
      this.props.video.attributes.hasOwnProperty("lockPlayer") &&
      this.props.video.attributes.lockPlayer
    ) {
      console.log("player is locked");
    } else {
      if (eventData.dir === "Left" || eventData.dir === "Right") {
        if (this.state.scenes.length > 0) {
          let currentTime = this.player.currentTime();
          let currentScene = null;

          for (let scene of this.state.scenes) {
            if (currentTime > parseFloat(scene.startTime) && currentTime < parseFloat(scene.endTime))
              currentScene = scene.number;
          }
          if (currentScene) {
            if (currentScene < this.state.scenes.length && eventData.dir === "Left") {
              for (let scene of this.state.scenes)
                if (scene.number === currentScene + 1) {
                  this.player.currentTime(parseFloat(scene.startTime));
                  this.player.play();
                  window.passedScene = currentScene;
                }
            }
            if (currentScene > 1 && eventData.dir === "Right")
              for (let scene of this.state.scenes)
                if (scene.number === currentScene - 1) {
                  this.player.currentTime(parseFloat(scene.startTime));
                  this.player.play();
                  window.passedScene = currentScene - 2;
                }
          }
        }
      }
    }
  }

  tapEvent(eventData) {
    //console.log(eventData);
  }

  render() {
    let videoStyle = { display: "block" };
    /*
    if (this.props.video.attributes.height > this.props.video.attributes.width) {
      delete videoStyle.minWidth;
      videoStyle.minHeight = "70vh";
      videoStyle.padding = 0;
    } */

    let skeleton = null;
    console.log("loadeddata value", this.state.videoloadeddata);

    if (!this.state.videoloadeddata && this.props.adjustedHeight) {
      videoStyle.display = "none";
      /*skeleton = (
        <div className="skeleton-loader">
          <div
            className="skeleton-video-placeholder"
            style={{
              width: "100%",
              height: this.props.adjustedHeight || "480px",
              backgroundColor: "#e0e0e0",
              animation: "pulse 1.5s infinite",
              borderRadius: "4px",
            }}
          ></div>

          <style>{`
            @keyframes pulse {
              0% {
                opacity: 0.6;
              }
              50% {
                opacity: 1;
              }
              100% {
                opacity: 0.6;
              }
            }
          `}</style>
        </div>
      ); */

      skeleton = (
        <img src={this.props.posterImage} loading="lazy" style={{ width: "100%", height: this.props.adjustedHeight }} />
      );
    } else if (window.player) {
      videoStyle.display = "block";
      window.player.el().style.display = "block";
      window.player.el().childNodes[0].style.display = "block";
    }

    return (
      <div>
        {skeleton}
        <div
          data-vjs-player
          style={
            {
              /* width: "80%", maxWidth: "1920px" */
            }
          }
        >
          <Swipeable
            /* onSwiping={(eventData) => this.tapEvent(eventData)} */
            onSwiped={(eventData) => this.swipeEvents(eventData)}
            preventDefaultTouchmoveEvent={true}
            /*trackMouse={true}
          delta={0} */
          >
            <video
              onContextMenu={(e) => e.preventDefault()}
              ref={(node) => (window.videoNode = node)}
              style={videoStyle}
              className="video-js"
              poster={this.props.posterImage}
            >
              {/*this.state.gotcaptionshowing ? (
                <track kind="captions" src={this.state.captionstring} label="Caption" default mode="showing"></track>
              ) : null*/}
              {/*this.state.gotcaptionhiding ? (
                <track kind="captions" src={this.state.captionstring} label="Caption"></track>
              ) : null*/}
            </video>
          </Swipeable>
        </div>

        <div
          className="flex flex-row mt-1 mb-2 ml-5 mr-1 text-gray-600 justify-between"
          style={this.state.displayNotes}
        >
          <p>Notes:</p>
          {this.state.currentCues}
        </div>
      </div>
    );
  }
}

function whenAvailable(name, callback) {
  var interval = 10; // ms
  window.setTimeout(function () {
    if (name) {
      callback(name);
    } else {
      whenAvailable(name, callback);
    }
  }, interval);
}

function whenAvailableZingTouch(name, callback) {
  if (process.browser) {
    var interval = 10; // ms
    window.setTimeout(function () {
      if (ZingTouch && ZingTouch.region) {
        callback(name);
      } else {
        whenAvailableZingTouch(name, callback);
      }
    }, interval);
  }
}
